<template>
    <UCard>
        <template #header>
            <UContainer class="w-full flex flex-row max-w-full items-center px-0 mx-0">
                <UHorizontalNavigation :links="links"></UHorizontalNavigation>
                <UDivider orientation="vertical" class="mx-2 h-max" />
                <UButton @click="signOut()" size="md" color="red" icon="i-heroicons-arrow-right-start-on-rectangle">
                    Çıkış Yap
                </UButton>
            </UContainer>
        </template>
        <slot />
    </UCard>
</template>

<script setup lang="ts">
const {data, signOut} = useAuth()
const links : {label:string, to:string}[] = [{
    label: "Öğrenci Listesi",
    to:"/students"
},{
    label: "Raporlar",
    to:"/reports"
},{
    label: "Ayarlar",
    to:"/settings"
},{
    label: "Arşiv",
    to:"/archive?type=mezun"
}]
</script>